:root {
  --button-idle: #373b49;
  --button-active: rgb(60, 61, 68);
  --button-activer: #737583;
  --palette-red: #E76D83;
  --palette-red-shade: #ad5262;
  --palette-yellow: #ffc600;
  --sandbox-project: rgb(134, 92, 14);
  --sandbox-project-tint: #a4854a;
  --sandbox-active-color: #BD93F9;
  --sandbox-background: #20222a;
}

.Top {
  text-align: center;
  color: #f6f7eb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ContentBox {
  background-color: #1e1f22;
  padding: 0 5px 0 5px;
  width: 100%;
  min-height: 100vh;
}

.MyName {
  font-size: 3em;
  font-family: Rajdhani;
}

.Section-title {
  font-size: 2em;
  font-family: Rajdhani;
}

.About {
  font-size: 1.2em;
  margin-bottom: 15px;
}

.Navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.Skills-section {
  justify-content: center;
  text-align: left;
  display: inline-block;
  font-size: 1.3em;
}

.Contact {
  font-size: 1.85em;
}

.Projects {
  margin-top: 10px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Project {
  text-align: left;
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  max-width: 900px;
}

.Project-bg {
  filter: blur(4px) brightness(50%);
  -webkit-filter: blur(4px) brightness(50%);
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Project-content {
  padding: 15px;
  z-index: 1;
  display: flex;
}

.Project-details {
  margin-right: 30px;
}

.Project-title {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 10px;
}

.Project-description {
  font-size: 1.1em;
}

.Project-links {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

.Project-button {
  border: solid 1px rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  padding: 10px 0px 10px 0px;
  /* text-decoration: underline; */
  text-align: center;
  font-size: 1.1rem;
  font-weight: 550;
  width: 170px;
  color: white;
}

.Project-button:hover {
  text-decoration: underline;
  border-color: white;
}

@media (max-width: 600px) {
  .Project-content {
    flex-wrap: wrap;
  }

  .Project-links {
    flex-direction: row;
  }

  .ContentBox {
    padding: 0;
  }
}

.App-link {
  color: #2d82b7;
}

.Email {
  color: var(--palette-red);
  font-family: 'Roboto Mono', monospace;
}

.Lang {
  color: var(--palette-yellow);
  font-family: 'Roboto Mono', monospace;
}

a {
  color: white;
}

/* - tw - */
.Row-reverse {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
  flex: 1 1;
}

/* - ostw sandbox - */
.Ostw-sandbox {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  background-color: var(--sandbox-background);
  overflow: hidden;
}

.Ostw-sandbox-top, .Ostw-sandbox-top-small {
  background-color: #20222a;
  color: white;
  text-align: center;
  padding: 5px;
  z-index: 1;
}

.Ostw-sandbox-top {
  display: flex;
  justify-content: flex-end;
}

.Ostw-sandbox-top-small-row {
  display: flex;
  justify-content: space-between;
  flex-shrink: 2;
  gap: 15px;
}

.Ostw-sandbox-file-info {
  text-align: left;
  margin-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Ostw-sandbox-project-name {
  font-weight: 600;
  font-family: 'Roboto Mono';
}

.Ostw-sandbox-file-name {
  margin-left: 3px;
}

.Ostw-sandbox-top-title, .Ostw-sandbox-top-title-small {
  font-family: 'Roboto Mono';
  font-size: 1.3em;
  white-space: nowrap;
}
.Ostw-sandbox-top-title {
  flex: 1;
}
.Ostw-sandbox-top-title-small {
  text-overflow: ellipsis;
  overflow: hidden;
}

.Ostw-sandbox-external-link,
.Ostw-sandbox-button-confirm {
  font-family: 'Roboto Mono';
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px transparent;
  border-radius: 10px;
  transition: 0.2s;
  background-color: var(--button-idle);
  color: white;
  user-select: none;
  text-align: center;
  font-size: .95em;
  padding: 3px;
  box-sizing: border-box;
}

.Ostw-sandbox-external-link img {
  display: block;
  max-width: 20px;
  max-height: 20px;
  width: auto;
  height: auto;
}

.Ostw-sandbox-external-link:hover {
  border-color: rgb(145, 145, 145);

}

.Ostw-sandbox-external-link:active {
  background-color: #505460;
  transition: 0s;
}

.Ostw-sandbox-button-confirm {
  pointer-events: none;
  animation: fadeout 1s linear forwards;
  position: absolute;
  background-color: green;
  left: 0;
  right: 0
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.Ostw-sidebar, .Ostw-sidebar-overlay {
  z-index: 1;
  box-shadow: -7px 0px 10px rgba(0, 0, 0, 0.303);
  height: 100%;
}

.Ostw-sidebar-overlay {
  min-width: min(325px, 100%);
  width: 90%;
  position: absolute;
  background-color: var(--sandbox-background);
  right: 0;
  transition: .2s;
  z-index: 2;
}

.Ostw-sidebar-hidden {
  right: min(-90%, -350px);
  box-shadow: -7px 0px 10px transparent;
}

.Ostw-sidebar-overlay-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.363);
  z-index: 1;
  top: 0;
  transition: .2s;
}

.Ostw-sidebar-overlay-bg-hidden {
  background-color: transparent;
  pointer-events: none;
}

.Sandbox-tab-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Sidebar-tab-content {
  color: whitesmoke;
  overflow-y: scroll;
  flex: 1;
}

.Ostw-sidebar-spacing {
  margin-top: 10px;
}

.Ostw-sidebar-title {
  border-top: 1px solid black;
  box-shadow: 0px 0px 6px black;
  background-color: var(--sandbox-project);
  padding: 3px;
  font-weight: 700;
  text-align: center;
  font-size: 1.2em;
  margin: 0 20px 0 20px;
}

.Ostw-sidebar-title:hover {
  background-color: var(--sandbox-project-tint);
  transition: 0.15s;
}

.Ostw-sidebar-opened {
  box-shadow: inset 0px 0px 6px black;
  background-color: var(--sandbox-project-tint);
}

.Ostw-sidebar-title-arrow {
  display: inline-block;
  position: absolute;
  margin-left: 10px;
  font-size: .7em;
  padding: inherit;
}

.Ostw-sidebar-title-arrow-opened {
  transform: rotate(180deg) translateY(-3px);
}

.Sandbox-tabs {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.43);
}

.Sandbox-tab-button {
  color: #808080;
  background-color: var(--sandbox-background);
  font-size: 14px;
  border-top: solid 2px #00000000;
  padding: 6px;
  flex: 1;
  text-align: center;
  transition: color 100ms;
  min-width: 0;
  user-select: none;
}

.Sandbox-tab-button:hover {
  color: #f8f8f0;
}

.Sandbox-tab-button-active {
  background-color: var(--button-active);
  border-color: #f8f8f0ad;
  color: #f8f8f0;
}

.Drag-panel-container-horizontal,
.Drag-panel-container-vertical {
  display: flex;
  flex: 1;
}

.Drag-panel-container-horizontal {
  flex-direction: row;
  min-height: 0;
}

.Drag-panel-container-vertical {
  flex-direction: column;
  min-width: 0;
}

.Drag-panel-container-horizontal .Dragging-panel {
  cursor: col-resize;
  user-select: 'none';
}

.Drag-panel-container-vertical .Dragging-panel {
  cursor: row-resize;
  user-select: 'none';
}

/* + Drag panel line */
.Drag-panel-line-horizontal,
.Drag-panel-line-vertical {
  background-color: var(--sandbox-active-color);
  z-index: 2;
}

.Drag-panel-line-horizontal {
  width: 2px;
}

.Drag-panel-line-vertical {
  height: 2px;
}

.Drag-panel-line-hitbox-horizontal,
.Drag-panel-line-hitbox-vertical {
  position: relative;
  z-index: 40;
}

.Drag-panel-line-hitbox-horizontal {
  left: -7.5px;
  height: 100%;
  width: 15px;
  cursor: col-resize;
}

.Drag-panel-line-hitbox-vertical {
  top: -7.5px;
  height: 15px;
  width: 100%;
  cursor: row-resize;
}

.Drag-panel-line-hitbox-horizontal:hover .Drag-panel-line-visual-horizontal,
.Drag-panel-line-visual-horizontal-active {
  background-color: lightblue;
  width: 5px;
  transition: 0.2s;
  height: 100%;
  left: 7.5px;
  position: absolute;
}

.Drag-panel-line-hitbox-vertical:hover .Drag-panel-line-visual-vertical,
.Drag-panel-line-visual-vertical-active {
  background-color: lightblue;
  width: 100%;
  transition: 0.2s;
  height: 5px;
  top: 7.5px;
  position: absolute;
}

/* - Drag panel line */


.Project-file-container {
  display: flex;
  flex-direction: row;
}

.Project-file,
.Project-file-opened,
.Project-file-rename {
  color: whitesmoke;
  transition: 0.2s;
  padding: 5px;
  height: 25px;
  user-select: none;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Project-file-opened {
  background-color: var(--button-active);
}

.Project-file:hover {
  background-color: var(--button-idle);
}

.Project-file-rename {
  background-color: #2e3148;
  border: solid 1px whitesmoke;
}

.Project-file-rename input[type="text"] {
  background: transparent;
  border: none;
  color: white;
  outline: none;
  width: 100%;
}

.Rename-notification {
  position: absolute;
  top: 100;
  padding: 5px;
  border: solid 1px yellow;
  background-color: #403200;
  color: white;
}

.Row {
  display: flex;
  flex-direction: row;
}

.Row * {
  flex: 1;
}

.Gap-5 {
  gap: 5px;
}

.Transition-02s {
  transition: .2s;
}

.Sandbox-modal-overlay {
  z-index: 3;
  position: fixed;
  inset: 0;
  background-color: #2d82b72d;
}

.Sandbox-modal-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px;
  outline: none;
  background-color: #20222a;
  color: white;
  border-radius: 25px;
  font-size: large;
  text-align: center;
}

.Sandbox-modal-content-error {
  border: solid 2px var(--palette-red);
  background-color: #1d0e10;
  padding: 6px;
}

.Monospace,
.Sandbox-control {
  font-family: 'Roboto Mono';
}

.Sandbox-control {
  background-color: rgb(59, 59, 59, 0.8);
  padding: 5px;
  border-radius: 7px;
}

.Sandbox-project-container {
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Sandbox-project {
  padding: 10px;
  font-size: 1.2em;
  border: 1px solid gray;
  border-left-width: 5px;
  flex: 1;
}

.Sandbox-project:hover {
  border-color: lightblue;
  transition: 0.2s;
}

.Sandbox-project-opened {
  background-color: var(--sandbox-project);
  border-color: orange;
  font-weight: 600;
}

.Sandbox-inline-button {
  height: calc((1.70em));
  padding: 0.15em;
  margin: 0.15em;
  border-radius: 5px;
}

.Sandbox-inline-button:hover {
  background-color: var(--button-idle);
}

.Sandbox-inline-button:active {
  background-color: var(--button-active);
}

.Sandbox-button:hover {
  background-color: var(--button-idle);
}

.Sandbox-button:active {
  background-color: var(--button-active);
}

.Sandbox-highlight-button {
  transition: .2s;
  filter: invert(.6);
}
.Sandbox-highlight-button:hover {
  filter: invert(.9);
}

.Sandbox-saves-dropdown {
  animation: slideUpFadeIn 0.15s linear forwards;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 1;
  left: 0;
  right: 0;
  padding: 10px;
  backdrop-filter: blur(20px);
  border: solid 2px black;
  box-shadow: 0px 0px 7px black;
  z-index: 1;
}

@keyframes slideUpFadeIn {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.Notification-list {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 30px;
  margin-bottom: 30px;
  z-index: 2;
  width: 500px;
  box-shadow: -5px -5px 8px black;
}

.Notification {
  background-color: rgb(221, 221, 221);
  border-bottom: solid black 1px;
}

.Notification-title {
  padding: 8px;
  display: inline-block;
}

.Notification-X {
  float: right;
  color: black;
  padding: 8px;
}

.Notification-X:hover {
  color: white;
  background-color: red;
}

.Notification-text {
  background-color: white;
  max-height: 200px;
  overflow-wrap: break-word;
  overflow-y: scroll;
  padding: 15px;
}